<template>
  <div :class="getExpandStatus ? 'body-pd' : 'body-expand-pd'">
    <Navigation />
    <Header title="Create New Ticket " />
    <Form
      @submit="createTicket"
      :validation-schema="schema"
      :initial-values="formValues"
    >
      <div class="card">
        <div class="flex-row newAccount">
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <label for="title">Title:</label>
                <Field name="title" type="text" />
                <ErrorMessage name="title" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <label for="details">Detail:</label>
                <Field name="details" as="textarea" rows="8" />
                <ErrorMessage name="details" class="error-feedback" />
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <label for="location">Location:</label>
                <Field name="locationName" type="text" disabled />
              </div>
              <div class="inputGroup">
                <label for="ticketType">Ticket Type:</label>
                <Field name="ticketType" as="select">
                  <option value="17">Change Request</option>
                  <option value="10">Problem</option>
                  <option value="9">Service Request</option>
                  <option value="8">Incident</option>
                </Field>
                <ErrorMessage name="ticketType" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <label for="priority">Priority:</label>
                <Field name="priority" as="select">
                  <option value="27032">Emergency</option>
                  <option value="26918">High</option>
                  <option value="26920">Medium</option>
                  <option value="26919">Low</option>
                </Field>
                <ErrorMessage name="priority" class="error-feedback" />
              </div>
              <div class="inputGroup" v-if="hardwareAssets">
                <label for="hardwareAsset">Affected Device:</label>
                <Field name="hardwareAsset" as="select">
                  <option
                    :key="hardware.Id"
                    v-for="hardware in hardwareAssets"
                    :value="hardware.Id"
                    >{{ hardware.Name }}</option
                  >
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row pb2">
          <button class="purple-button" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Create Ticket</span>
          </button>
          <button type="reset" class="grey-button">Cancel</button>
        </div>
        <div v-if="message" class="flex-row pb">
          <div
            class="alert"
            :class="successful ? 'alert-success' : 'alert-danger'"
            role="alert"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </Form>
    <Footer />
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ServiceDeskService from "../services/serviceDesk.service";

export default {
  name: "NewTicket",
  components: {
    Navigation,
    Header,
    Footer,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      title: yup
        .string()
        .required("Tittle is required!")
        .min(5, "Must be minimum 5 characters!"),
      details: yup
        .string()
        .required("Detail is required!")
        .min(10, "Must be minimum 10 characters!"),
      ticketType: yup.string().required("Ticket Type is required!"),
      priority: yup.string().required("Priority is required!"),
    });

    var formValues = {
      locationName: "locationName",
    };
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      formValues,
      hardwareAssets: "",
      ticketDetails: "",
      user: "",
    };
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.formValues.locationName = user.locationName;
    this.user = user;

    this.hardwareAssets = this.$store.state.serviceDesk.hardwareAssets;

    if (!this.hardwareAssets) {
      await this.$store.dispatch("serviceDesk/initializeHardwareAssets");
    }

    this.hardwareAssets = this.$store.state.serviceDesk.hardwareAssets;
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getExpandStatus() {
      return this.$store.state.responsive.expand;
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    async createTicket(ticket) {
      try {
        this.successful = true;
        this.loading = true;
        await ServiceDeskService.createTicket(ticket, this.user.email);
        this.message = "Ticket Created Successfully";
        this.loading = false;
      } catch (error) {
        this.successful = false;
        this.loading = false;
        this.message = "Ticket not Created, Pleae try again!";
      }

      setTimeout(() => {
        this.$router.push({ name: "dashboard" });
      }, 2250);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.successful) {
      this.$store.dispatch("serviceDesk/initializeTickets");
    }
    next();
  },
};
</script>

<style scoped>
.alert {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.71rem 1rem;
  margin-bottom: 0;
  margin-top: 0.71rem;
}

.alert-danger {
  background-color: #fbdddd;
  border-color: #f9cfcf;
  box-shadow: rgb(234 84 85 / 40%) 0px 6px 15px -7px;
  color: #ea5455;
}

.pb2 button {
  width: 120px;
  text-align: center;
  padding: 0.35rem 1.5rem;
}

.pb2 button:first-child {
  margin-top: 0rem;
  margin-right: 1rem;
  width: 190px;
}

form {
  margin-right: 1rem;
}

label {
  color: #5e5873;
  font-size: 0.857rem;
  margin-bottom: 0.2857rem;
  display: block;
}

input,
textarea {
  overflow: hidden;
  padding: 0.35rem 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  width: 100%;
}

.flex-row {
  margin-bottom: 1rem;
}

.card {
  width: 100%;
}

.col1 {
  width: 50%;
  max-width: 50%;
  margin-right: 0.5rem;
}

.col1:first-child {
  margin-right: 1.5rem;
}

button {
  margin-right: 1rem;
}

.inputGroup {
  margin-bottom: 1rem;
}

select {
  color: #6e6b7b;
  background-color: #fff;
  cursor: default;
  margin: 0em;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.35rem 0.2rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  font-family: "poppins";
  width: 100%;
}

input:focus {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
  background-color: #fff !important;
  border-color: #7367f0 !important;
}

.error-feedback {
  color: #ea5455;
  font-size: 0.8rem;
  font-family: "poppins";
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .pb2 button:first-child {
    width: 150px;
    padding: 0.35rem 0.5rem;
  }

  .pb2 button {
    width: 90px;
    padding: 0.35rem 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .newAccount .flex-row {
    flex-wrap: wrap;
  }
  .newAccount .col1 {
    width: 100%;
    max-width: 100%;
    margin-right: 0rem;
    margin: 0rem;
  }
}

@media only screen and (max-width: 668px) {
  form {
    margin-right: 0.5rem;
  }
}
</style>
